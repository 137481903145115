import axios from "axios";

import {
  collection,
  getDocs,
  // query,
  // where,
  // orderBy,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "auth/FirebaseAuth";
import { AUTH_USER_DATA } from "constants/AuthConstant";
import { API_BASE_URL } from "constants/ApiConstant";

const FirebaseArticlesService = {};

FirebaseArticlesService.fetchArticles = async projectId => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const url = `${API_BASE_URL}/articles/${owner_uid}/${projectId}`;
    const result = await axios.get(url);

    // const articlesCollectionRef = await collection(
    //   db,
    //   `users/${owner_uid}/projectsData/${projectId}/articles`
    // );
    // const querySnapshot = await getDocs(
    //   query(articlesCollectionRef, orderBy("dateCreated", "desc"))
    // );
    // const articlesData = [];
    // querySnapshot.forEach(doc => {
    //   const data = doc.data();
    //   articlesData.push(data);
    // });

    return result.data.articlesData;
  } catch (error) {
    throw error;
  }
};

FirebaseArticlesService.getArticlesCounts = async projectId => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const url = `${API_BASE_URL}/articles/counts/${owner_uid}/${projectId}`;
    const result = await axios.get(url);

    return result.data;
  } catch (error) {
    throw error;
  }
};

// FirebaseArticlesService.getArticleById = async (projectId, articleId) => {
//   try {
//     if (!projectId)
//       projectId = await FirebaseArticlesService.getProjectId(articleId);

//     const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
//     const articletDataDocRef = doc(
//       db,
//       `users/${owner_uid}/projectsData/${projectId}/articles/${articleId}`
//     );

//     const articletDataSnapshot = await getDoc(articletDataDocRef);

//     if (articletDataSnapshot.exists()) {
//       const articleData = articletDataSnapshot.data();
//       return articleData;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     throw error;
//   }
// };

FirebaseArticlesService.getArticleById = async (projectId, articleId) => {
  try {
    if (!projectId)
      projectId = await FirebaseArticlesService.getProjectId(articleId);

    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));

    const articletDataDocRef = doc(
      db,
      `users/${owner_uid}/projectsData/${projectId}/articles/${articleId}`
    );

    const articletDataSnapshot = await getDoc(articletDataDocRef);

    if (articletDataSnapshot.exists()) {
      const articleData = articletDataSnapshot.data();

      const projectDataDocRef = doc(
        db,
        `users/${owner_uid}/projectsData/${projectId}`
      );
      const projectDataSnapshot = await getDoc(projectDataDocRef);

      if (projectDataSnapshot.exists()) {
        const projectData = projectDataSnapshot.data();

        const matchingProject = projectData.projectContentGenerationData.find(
          item => item.id === articleData.subjectId
        );

        if (matchingProject) {
          return { ...articleData, project: matchingProject.projectSubject };
        }
      }

      return articleData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseArticlesService.updateArticleById = async (
  projectId,
  articleId,
  data
) => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!owner_uid) throw new Error("User not authenticated");

    let articletDataDocRef;

    if (!projectId)
      projectId = await FirebaseArticlesService.getProjectId(articleId);

    articletDataDocRef = doc(
      db,
      `users/${owner_uid}/projectsData/${projectId}/articles/${articleId}`
    );

    const articletDataSnapshot = await getDoc(articletDataDocRef);

    if (articletDataSnapshot.exists()) {
      const updateData = {};
      for (const key in data) {
        if (typeof data[key] === "object" && !Array.isArray(data[key])) {
          for (const subKey in data[key]) {
            updateData[`${key}.${subKey}`] = data[key][subKey];
          }
        } else {
          updateData[key] = data[key];
        }
      }

      await updateDoc(articletDataDocRef, updateData);
      const updatedArticleSnapshot = await getDoc(articletDataDocRef);
      const updatedArticleData = updatedArticleSnapshot.data();

      return updatedArticleData;
    } else {
      throw new Error("Article not found at the resolved path");
    }
  } catch (error) {
    console.error("Error updating article:", error);
    throw error;
  }
};

FirebaseArticlesService.hideArticleById = async (articleId, projectId) => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!owner_uid) throw new Error("User not authenticated");

    let articletDataDocRef;
    console.log(`projectId`, projectId);
    if (!projectId)
      projectId = await FirebaseArticlesService.getProjectId(articleId);

    // Теперь у нас есть projectId, можем использовать его для получения ссылки на документ
    articletDataDocRef = doc(
      db,
      `users/${owner_uid}/projectsData/${projectId}/articles/${articleId}`
    );

    const articletDataSnapshot = await getDoc(articletDataDocRef);

    if (articletDataSnapshot.exists()) {
      // Обновляем данные с использованием точечной нотации для вложенных полей
      const updateData = { hide: true };

      await updateDoc(articletDataDocRef, updateData);

      const updatedArticleSnapshot = await getDoc(articletDataDocRef);
      const updatedArticleData = updatedArticleSnapshot.data();

      return updatedArticleData;
    } else {
      throw new Error("Article not found at the resolved path");
    }
  } catch (error) {
    console.error("Error updating article:", error);
    throw error;
  }
};

FirebaseArticlesService.ensureCredentialsForPublishing = async (
  projectId,
  articleId
) => {
  const credentials = {
    twitter: false,
    facebook: false,
    site: false,
  };
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!owner_uid) throw new Error("User not authenticated");

    let projectDataDocRef;

    if (!projectId)
      projectId = await FirebaseArticlesService.getProjectId(articleId);

    // Теперь у нас есть projectId, можем использовать его для получения ссылки на документ
    projectDataDocRef = doc(db, `users/${owner_uid}/projectsData/${projectId}`);

    const project = await getDoc(projectDataDocRef);

    if (project.exists()) {
      const projectData = project.data();
      // console.log(`projectData`, projectData);
      if (
        projectData?.site &&
        projectData?.site?.siteUrl &&
        projectData?.site?.name
      ) {
        credentials.site = true;
      }

      if (
        projectData?.socialData?.some(social => social.type === "Twitter/X")
      ) {
        credentials.twitter = true;
      }

      if (projectData?.socialData?.some(social => social.type === "Facebook")) {
        credentials.facebook = true;
      }
    }
    return credentials;
  } catch (error) {
    console.error("Error updating article:", error);
    return credentials;
  }
};

FirebaseArticlesService.getProjectId = async articleId => {
  try {
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!owner_uid) throw new Error("User not authenticated");

    const projectsCollectionRef = collection(
      db,
      `users/${owner_uid}/projectsData`
    );

    const projectsSnapshot = await getDocs(projectsCollectionRef);

    if (projectsSnapshot.empty) {
      throw new Error("No projects found");
    }

    // Найти проект, в подколлекции articles которого содержится нужная статья
    let foundProjectId = null;
    for (const projectDoc of projectsSnapshot.docs) {
      const projectId = projectDoc.id;
      const articlesCollectionRef = collection(
        db,
        `users/${owner_uid}/projectsData/${projectId}/articles`
      );
      const articleDocRef = doc(articlesCollectionRef, articleId);
      const articleDocSnapshot = await getDoc(articleDocRef);

      if (articleDocSnapshot.exists()) {
        foundProjectId = projectId;
        break;
      }
    }

    if (!foundProjectId) {
      throw new Error("Project containing the article not found");
    }

    return foundProjectId;
  } catch (error) {
    console.error("Error searching projectId:", error);
    throw error;
  }
};

FirebaseArticlesService.postArticle = async data => {
  try {
    const url = `${API_BASE_URL}/articles/create`;
    // const response = await axios.post(url, data);
    const response = await axios.post(url, data);
    return response.data;
    // return;
  } catch (error) {
    throw error;
  }
};

export default FirebaseArticlesService;
